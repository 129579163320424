/*** By Subbiah ***/
::-webkit-scrollbar {
    width: 0.375rem;
}
::-webkit-scrollbar-thumb {
    background-color: #00000040;
}

.app-header .menu-item .dropdown-toggle::after{
	display: none;
}
.app-header .menu .menu-item .menu-link {
    padding: 10px;
}
.app-header .menu-item .menu-text{
    color: #000;
}

.app-header .menu-item .dropdown>a{
    padding: 0px;
}
.app-header .dropdown-menu{
    margin-top: 15px;
}
.app-header .dropdown-menu .dropdown-item {
    padding: 3px 10px;
	font-size: 13px;
}
.app-header .dropdown-divider {
    margin: 3px 0;
}
.app-header .nav-left .dropstart .dropdown-menu {
    top: 56px;
	right: 10%;
}
.app-header .nav-left .dropstart .dropdown-menu:before {
	left: auto;
	right: 12px;
}
.app-header{
	height: 45px;
	/* left: 49px; */
	box-shadow: none;
	border-bottom: 1px solid #dee2e6;
}
.app-header .menu{
	margin-top: -7px;
}
.app-header .brand {
    width: auto;
    height: 0px;
	padding: 0 20px;
	border-right: 1px solid #efefef;
}
.app-header .brand .brand-logo {
    font-weight: 700;
    color: #212837;
    font-size: 20px;
    text-decoration: none;
    height: 0;
    width: 100%;
    padding: 0 0;
    display: flex;
    align-items: center;
    font-size: 17px;
}
.app-header .dropdown-menu:before {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    left: 10px;
	border-bottom: 11px solid #fff;
    top: -11px;
}

.app-header a.top-nav-active{
	background: linear-gradient(357deg, #d9d9d9, #f7f3f399);
    border-radius: 3px;
}
.app-content {
	padding: 5px 20px;
}
.page-headers{
	position: sticky;
    top: 55px;
    z-index: 1;
    background-color: #ebeef4;
    padding: 5px 0 10px 0;
}
.no-shadow{
	box-shadow: none;
}
/*** By Subbiah ***/


.modal-frame-top{
	position: absolute;
    max-width: 100%;
    width: 100%;
    margin: 0;
}
.modal-frame-top .modal-content{
	border-radius: 0;
}

#page_image {
    background-image: url("./images/bg.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
 }

#app_name {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #903e32;
}

.receiptprint {
	position:absolute;
	left:26%;
	width:1000px;
	font-size: 17px;
}
.receiptprint span {
	position:absolute;
	font-size: 17px;
}

.ant-table-container{
overflow: scroll;

}

.links {
    text-decoration: none;
    color: black; 
}